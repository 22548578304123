import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { deleteAccount, fetchProfile } from "../../Utils/Features/profileSlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus.js";
import { BillingGrid } from "../Billing/Billing";
import { fetchStats } from "../../Utils/Features/profileSlice.js";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton.jsx";
import { SUPPORT_EMAIL } from "../../Utils/constants.js";
import { paymentStatuses } from "../Billing/subscriptionTypes.js";

const DeleteAccount = () => {
  const dispatch = useDispatch();

  const { profileData, profileStatus, deleteStatus, deleteReason } =
    useSelector((state) => state?.profileSliceReducer);

  const { books, highlights, ideas, statsStatus } = useSelector(
    (state) => state.profileSliceReducer.stats
  );
  useEffect(() => {
    if (statsStatus !== ApiStatus.Fulfilled) {
      dispatch(fetchStats());
    }
  }, [dispatch, statsStatus]);

  useEffect(() => {
    if (profileStatus !== ApiStatus.Fulfilled) dispatch(fetchProfile());
    // fetch profile only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (e) => {
    dispatch(deleteAccount(deleteReason));
  };

  if (deleteStatus === ApiStatus.Pending) {
    return (
      <Container>
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={12} justifyContent="center">
            <Card>
              <CardContent>
                Your account is being marked for deletion. We're sorry to see
                you go!
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  } else if (
    deleteStatus === ApiStatus.Fulfilled ||
    profileData?.accountDeleted !== undefined
  ) {
    return (
      <Container>
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={12} justifyContent="center">
            <Card>
              <CardContent>
                Your account has been marked for deletion, permanent deletions
                are executed on Sundays. <br />
                <br />
                We're sorry to see you go! You may log out now.
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  } else if (deleteStatus === ApiStatus.Rejected) {
    return (
      <Container>
        <Card>
          <CardContent>
            <div className="contentCentered">
              Oops.. deletion failed, please try again in a moment. 🙃
              <DeleteButton handleDelete={handleDelete} />
              If the problem persists, please contact our
              <a href={`mailto:${SUPPORT_EMAIL}`}>support team</a> for
              assistance. We´re here to help!
            </div>
          </CardContent>
        </Card>
      </Container>
    );
  } else {
    return (
      <Container>
        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={12} justifyContent="center">
            <Card>
              <CardContent>
                <Grid container spacing={0} sx={{ alignItems: "center" }}>
                  <BillingGrid profileData={profileData} />
                  {profileData.paymentStatus === paymentStatuses.Paid && (
                    <Grid item xs={12} sx={{ color: "red" }}>
                      <Typography variant="h6">
                        Your Stripe subscription will be cancelled
                        automatically. Please check in the portal after you have
                        deleted your account.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Grid container spacing={0} sx={{ alignItems: "center" }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Are you sure you want to permanently delete your account?​
                      <br />
                      All your data will be removed from our servers:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className="leftLabel">
                    <Typography variant="h6">Books: </Typography>
                  </Grid>
                  <Grid item xs={6} className="rightValue">
                    <Typography variant="h6">{books}</Typography>
                  </Grid>
                  <Grid item xs={6} className="leftLabel">
                    <Typography variant="h6">Highlights: </Typography>
                  </Grid>
                  <Grid item xs={6} className="rightValue">
                    <Typography variant="h6">{highlights}</Typography>
                  </Grid>
                  <Grid item xs={6} className="leftLabel">
                    <Typography variant="h6">Ideacards: </Typography>
                  </Grid>
                  <Grid item xs={6} className="rightValue">
                    <Typography variant="h6">{ideas}</Typography>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12}>
                  <Typography variant="h6">
                    If you wish to export your data, please do so before
                    proceeding, as you will lose access to your data when you
                    click delete and it will be permanently deleted on the next
                    Sunday.
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <AccountDeletionForm />
          </Grid>
        </Grid>
      </Container>
    );
  }
};

const AccountDeletionForm = () => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState();
  const [unhappyReason, setUnhappyReason] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [error, setError] = useState("");

  const handleDelete = (e) => {
    e.preventDefault();
    console.log("first time delete");
    if (confirmation?.toLowerCase() !== "delete") {
      setError("Please type DELETE to confirm deletion.");
      return;
    }
    setError("");

    dispatch(deleteAccount({ reason, otherReason, unhappyReason }));
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          We're sorry to see you go! Could you let us know your reason for
          deleting your account?
        </Typography>
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <RadioGroup
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <FormControlLabel
              value="Not happy with the service"
              control={<Radio />}
              label="Not happy with the service (please specify):"
            />
            {reason === "Not happy with the service" && (
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Could you please specify what you were not happy with, so we can improve our service..."
                value={otherReason}
                onChange={(e) => setUnhappyReason(e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
            <FormControlLabel
              value="Too expensive"
              control={<Radio />}
              label="Too expensive"
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label="Other reason (please specify):"
            />
            {reason === "Other" && (
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Your reason..."
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
          </RadioGroup>
        </FormControl>
        <Typography variant="body1" gutterBottom>
          <strong>Important:</strong> If you really wish to delete your account,
          please type <strong>DELETE</strong> below.
        </Typography>
        <TextField
          fullWidth
          placeholder="Type DELETE to confirm"
          value={confirmation}
          onChange={(e) => setConfirmation(e.target.value)}
          error={!!error}
          helperText={error}
          sx={{ mb: 3 }}
        />
        <DeleteButton handleDelete={handleDelete} />
      </CardContent>
    </Card>
  );
};

const DeleteButton = ({ handleDelete }) => {
  return (
    <div className="contentCentered">
      <ClearIcon
        sx={{
          stroke: "red",
          strokeWidth: 6,
          fontSize: "3rem",
        }}
      />
      <PrimaryButton buttonClass="backgroundRedBig" onClick={handleDelete}>
        Delete Account
      </PrimaryButton>
    </div>
  );
};

export default DeleteAccount;
