import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PaymentsIcon from "@mui/icons-material/Payments";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";

import { PrimaryButton } from "../Common/Buttons/PrimaryButton.jsx";
import {
  createCheckoutSession,
  createPortalSession,
} from "../../Utils/Payment/paymentSlice";
import { fetchProfile } from "../../Utils/Features/profileSlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus.js";
import { customLog } from "../../helperFunctions/customLogger.js";
import useTabActive from "../Common/UseTabActive.js";

import {
  subscriptionTypes,
  subscriptionLetters,
  paidSubscriptionTypes,
  subscriptionData,
  paymentStatuses,
} from "./subscriptionTypes";
import "./Billing.css";
import "../../pages/Success/SuccessPage.css";
import { pushToDataLayer } from "../../helperFunctions/gtm.js";

const StyledBullet = styled(Typography)({
  margin: 0,
  padding: "3px",
  lineHeight: "1.1rem", // Ensure text height is consistent
  height: "2.1rem", // Fixed height for the bullet point
  display: "flex",
  alignItems: "center",
});

const SubscriptionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "state",
})(({ state }) => ({
  backgroundColor: "var(--cardBackgroundColor)",
  marginBottom: "10px",
  padding:
    state === "subscribed" || state === "selected" ? "10px 0px" : "11px 0px",
  borderRadius: "12px",
  border:
    state === "subscribed"
      ? "2px solid var(--primaryColor)"
      : state === "selected"
      ? "2px solid var(--borderColorDark)"
      : "1px solid var(--borderColors)",
  "&:hover": {
    backgroundColor: "var(--HoverOver)",
    boxShadow: "none",
    border:
      state === "subscribed"
        ? "2px solid var(--primaryColor)"
        : state === "selected"
        ? "2px solid var(--borderColorDark)"
        : "1px solid var(--borderColors)",
  },
}));

const isProfileSubscribedPlan = (plan, profileData) => {
  return plan === profileData?.subscriptionType;
};

const BillingComponent = () => {
  const dispatch = useDispatch();

  const loadingPortal = useSelector((state) => state.payment.loadingPortal);
  const { profileData, profileStatus } = useSelector(
    (state) => state?.profileSliceReducer
  );
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    paidSubscriptionTypes.includes(profileData?.subscriptionType)
      ? profileData?.subscriptionType
      : subscriptionTypes.Monthly
  );

  const isTabActive = useTabActive();
  const [tabExited, setTabExited] = useState(false);

  useEffect(() => {
    if (isTabActive) {
      // check if we exited tab and then upon return refetch profile
      // (in case user went to portal and changed subscription we need new profile state)
      if (tabExited) {
        setTabExited(false);
        dispatch(fetchProfile());
      }
    } else {
      setTabExited(true);
    }
  }, [isTabActive]);

  customLog("createPortalSession handleState: loadingPortal: " + loadingPortal);

  useEffect(() => {
    if (profileStatus !== ApiStatus.Fulfilled) dispatch(fetchProfile());
    // fetch profile only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (event) => {
    const newSubscriptionType = event.currentTarget.value;
    if (newSubscriptionType && newSubscriptionType !== "") {
      setSelectedSubscriptionType(newSubscriptionType);
    }
  };

  const isSelectedSubscription = (plan) => {
    return plan === selectedSubscriptionType;
  };

  const isSubscribedPlan = (plan) => {
    isProfileSubscribedPlan(plan, profileData);
  };

  return (
    <Container>
      <Grid container spacing={0} sx={{ alignItems: "center" }}>
        <Grid item xs={12} justifyContent="center">
          <Card>
            <CardContent>
              <Grid container spacing={0} sx={{ alignItems: "center" }}>
                <BillingGrid profileData={profileData} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            className={`subscriptionBox ${
              isSubscribedPlan(subscriptionTypes.Free) ? `selectedCard` : ""
            }`}
          >
            <Box textAlign="center">
              <SubscriptionButton
                value={subscriptionTypes.Free}
                justifyContent="center"
                onChange={handleSelect}
                state={
                  isSelectedSubscription(subscriptionTypes.Free)
                    ? "selected"
                    : "normal"
                }
                style={{
                  width: "100px",
                }}
              >
                <Typography variant="body1">Free</Typography>
              </SubscriptionButton>
            </Box>

            <SubscriptionCard subscriptionType={subscriptionTypes.Free} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            className={`subscriptionBox
              ${
                isSubscribedPlan(selectedSubscriptionType) ||
                isSubscribedPlan(subscriptionTypes.Trial)
                  ? `selectedCard`
                  : ""
              }
          `}
          >
            <Box textAlign="center">
              {paidSubscriptionTypes.map((paidSubscriptionType) => (
                <SubscriptionButton
                  value={paidSubscriptionType}
                  onClick={handleSelect}
                  state={
                    isSubscribedPlan(paidSubscriptionType)
                      ? "subscribed"
                      : isSelectedSubscription(paidSubscriptionType)
                      ? "selected"
                      : "normal"
                  }
                >
                  <Typography variant="body1">
                    {subscriptionLetters[paidSubscriptionType]}
                  </Typography>
                </SubscriptionButton>
              ))}
            </Box>
            <SubscriptionCard
              subscriptionType={selectedSubscriptionType}
              trialDaysLeft={
                isSubscribedPlan(subscriptionTypes.Trial)
                  ? profileData?.trialDaysLeft
                  : undefined
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BillingComponent;

const SubscriptionCard = ({ subscriptionType, trialDaysLeft }) => {
  const dispatch = useDispatch();

  const getBulletPoints = (plan) => {
    return subscriptionData[plan]["benefits"];
  };

  const getPrice = (plan) => {
    return subscriptionData[plan]["price"];
  };

  const renderBulletPoints = (plan) => {
    return getBulletPoints(plan).map((point) => (
      <StyledBullet key={point} variant="body2">
        <CheckIcon
          className={point.paid ? "checkIcon checkIconColored" : "checkIcon"}
        />
        <div className="bulletText">{point.text}</div>
      </StyledBullet>
    ));
  };

  const handleCheckout = () => {
    pushToDataLayer("plan_selection_attempt", { plan: subscriptionType });
    dispatch(
      createCheckoutSession({
        subscriptionType,
      })
    );
  };

  return (
    <Card className="subscriptionCard">
      <div className="ribbon">
        {trialDaysLeft && <span>{trialDaysLeft} Days Left</span>}
      </div>
      <CardContent>
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          my={1}
        >
          <Card className="priceCard">
            <Typography variant="h6">{getPrice(subscriptionType)}</Typography>
          </Card>
          <PrimaryButton isPrimaryColor="true" onClick={handleCheckout}>
            Select
          </PrimaryButton>
          <PrimaryButton>
            <a href="https://deepread.com/" target="_blank" rel="noreferrer">
              Learn more
            </a>
          </PrimaryButton>
        </Box>
        <hr />
        <Box textAlign="left" py={1} className="checklist">
          {renderBulletPoints(subscriptionType)}
        </Box>
      </CardContent>
    </Card>
  );
};

export const BillingGrid = ({ profileData }) => {
  const dispatch = useDispatch();

  const [billingPortalLink, setBillingPortalLink] = useState("");
  const handleCreatePortalSession = async () => {
    try {
      customLog("Dispatching createPortalSession");
      const resultAction = await dispatch(createPortalSession()).unwrap();
      customLog("PortalSessionLink: " + resultAction);
      if (resultAction) {
        window.open(resultAction, "_blank");
        setBillingPortalLink(resultAction);
      } else {
        customLog("Result action is undefined or null");
      }
    } catch (error) {
      console.error("Failed to create portal session: ", error);
    }
  };

  // Billing Date Handler Function
  const handleBillingDate = (nextBillingDate) => {
    if (!nextBillingDate) {
      return "No billing date available";
    }
    if (nextBillingDate === "pending") {
      return "pending";
    }
    const date = new Date(nextBillingDate);
    if (isNaN(date.getTime())) {
      return null; //"Invalid date";
    }
    // Format the date as you prefer, for example:
    return date.toLocaleDateString({
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const billingDate = handleBillingDate(profileData?.stripe?.nextBillingDate);
  const subscriptionChange = profileData?.subscriptionChange;
  const subscriptionChangeDate = handleBillingDate(
    profileData?.subscriptionChange?.scheduleAt
  );

  return (
    <>
      <Grid item xs={6} className="leftLabel">
        <Typography variant="h6">Subscription: </Typography>
      </Grid>
      <Grid item xs={6} className="rightValue">
        <Typography variant="h6">{profileData?.subscriptionType}</Typography>
      </Grid>
      <Grid item xs={6} className="leftLabel">
        <Typography variant="h6">Status: </Typography>
      </Grid>
      <Grid item xs={6} className="rightValue">
        <Typography variant="h6">{profileData?.paymentStatus}</Typography>
      </Grid>
      {paidSubscriptionTypes.includes(profileData?.subscriptionType) &&
        profileData?.stripe?.nextBillingDate && (
          <>
            <Grid item xs={6} className="leftLabel">
              <Typography variant="h6">
                {subscriptionChange
                  ? "Subscription changing on:"
                  : "Next bill date:"}
              </Typography>
            </Grid>
            <Grid item xs={6} className="rightValue">
              <Typography variant="h6">
                {subscriptionChange ? subscriptionChangeDate : billingDate}
              </Typography>
            </Grid>
            {subscriptionChange && (
              <>
                <Grid item xs={6} className="leftLabel">
                  <Typography variant="h6">Changing to:</Typography>
                </Grid>
                <Grid item xs={6} className="rightValue">
                  <Typography variant="h6">
                    {subscriptionChange?.type}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
      {isProfileSubscribedPlan(subscriptionTypes.Trial, profileData) && (
        <>
          <Grid item xs={6} className="leftLabel">
            <Typography variant="h6">Trial days left:</Typography>
          </Grid>
          <Grid item xs={6} className="rightValue">
            <Typography variant="h6">{profileData?.trialDaysLeft}</Typography>
          </Grid>
        </>
      )}
      {profileData?.paymentStatus === paymentStatuses.Paid && (
        <>
          <Grid item xs={6} className="leftLabel">
            <Typography variant="h6">Billing portal:</Typography>
          </Grid>
          <Grid item xs={6} className="rightValue">
            <Button
              onClick={handleCreatePortalSession}
              style={{ margin: "4px" }}
              className="icon linkCollapsible flex items-center justify-center !h-[30px] !w-[80px] !rounded-full !bg-[#E3E3E3]"
            >
              <PaymentsIcon
                sx={{
                  fontSize: "1.5rem",
                  color: "#808080",
                }}
              />
            </Button>
            {billingPortalLink && (
              <a
                href={billingPortalLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                Link
              </a>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
