import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";

import "./Pages_V2.css";

import Filter from "../components/Common/Filter";
import Sort from "../components/Common/Sort";

import { ignoreOrderCompare } from "../helperFunctions/equalityChecker";
import { setLibraryFilter } from "../Utils/Features/userConfigSlice";
import { executeWithIncrementalDelay } from "../helperFunctions/timing";

import { fetchBookTags } from "../Utils/Features/librarySlice";
import { ApiStatus } from "../Utils/Features/ApiStatus";
import Search from "../components/Common/Search";
import { getNumericEnvVariable } from "../helperFunctions/envVars";

// Defined data for sorting and filter list
const sortingItemList = [
  { id: 0, sortField: "author", title: "Author", isChecked: false },
  { id: 1, sortField: "title", title: "Title", isChecked: true },
  { id: 2, sortField: "rating", title: "Rating", isChecked: false },
  { id: 3, sortField: "created_at", title: "Last Added", isChecked: false },
  { id: 4, sortField: "updated_at", title: "Last Edited", isChecked: false },
  { id: 5, sortField: "seen_at", title: "Last Viewed", isChecked: false },
  { id: 6, sortField: "h_progress", title: "Highlights", isChecked: false },
];

export const FilterEnum = {
  MY_OWN_CONTENT: "My own content",
  FOLLOWED: "Followed content",
};

const filterItemListOwnerShip = [
  {
    id: "own",
    title: FilterEnum.MY_OWN_CONTENT,
    isChecked: false,
    icon: "DiamondOutlinedIcon",
  },
  {
    id: "follow",
    title: FilterEnum.FOLLOWED,
    isChecked: false,
    icon: "BookmarkIcon",
  },
];

// Main LibraryViewMenu component
const LibraryViewMenu = () => {
  const [hideSortDropDown, sethideSortDropDown] = useState(true);
  const [showSortItem, setShowSortItem] = useState(true);
  const [showTags, setShowTags] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const sortingRef = useRef();
  const filterTagsRef = useRef();
  const filterOwnershipRef = useRef();
  const dispatch = useDispatch();
  const [filterItemTags, setFilterItemTags] = useState([]);
  const bookTags = useSelector((state) => state.library.bookTags);
  const bookTagsStatus = useSelector((state) => state.library.bookTagsStatus);

  const { libraryFilter: filter } = useSelector((state) => state.userConfig);
  // sort default item nro 5 = highlight => books are sorted by default by number of highlights
  const sortDefaultItem =
    sortingItemList.find((type) => type.sortField === filter?.sortFilter)?.id ??
    5;

  const [delayFilter, setDelayFilter] = useState(
    filter?.filter ?? { search: "" }
  );
  const [delaySortFilter, setDelaySortFilter] = useState(null);
  const [delayFilterTags, setDelayFilterTags] = useState(null);
  const [delayFilterOwnership, setDelayFilterOwnership] = useState(null);
  const [filterTimer, setFilterTimer] = useState(null);

  const [searchQuery, setSearchQuery] = useState(filter?.filter?.search ?? "");

  useEffect(() => {
    if (bookTags && bookTags.length >= 0) {
      const allTags = [...new Set(bookTags)];

      // Sort the tags in ascending order
      allTags.sort();

      const temp = allTags.map((tag, index) => ({
        id: index,
        title: tag,
        isChecked: filter?.filter?.tags?.includes(tag),
      }));
      setFilterItemTags(temp);
    }
    // Tags list for the filter should be set only at first render but not later when tags are checked or unchecked
    // so filter?.filter?.tags change is ignored
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookTags]);

  useEffect(() => {
    filterItemListOwnerShip.forEach((ownershipItem) => {
      ownershipItem.isChecked = filter?.filter?.ownership?.includes(
        ownershipItem.id
      );
    });
  }, [filter]);

  useEffect(() => {
    if (delayFilterTags !== null) {
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        tags: delayFilterTags,
      }));
    }
  }, [delayFilterTags]);

  useEffect(() => {
    if (delayFilterOwnership !== null) {
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        ownership: delayFilterOwnership,
      }));
    }
  }, [delayFilterOwnership]);

  useEffect(() => {
    const updatedFilter = {
      filter: delayFilter,
      sortFilter: delaySortFilter ?? filter?.sortFilter,
    };

    const delayTimeInMS = getNumericEnvVariable(
      "REACT_APP_FEED_FILTER_DELAY_INMS",
      800
    );
    if (!ignoreOrderCompare(filter, updatedFilter)) {
      executeWithIncrementalDelay(
        filterTimer,
        setFilterTimer,
        () => {
          dispatch(setLibraryFilter(updatedFilter));
        },
        delayTimeInMS
      );
    }
    // adding filterTimer to the dependency array creates infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayFilter, delaySortFilter, dispatch]);

  const HandleOutsideClick = (event, isForcefullyPrevented = false) => {
    if (sortingRef.current && !sortingRef.current.contains(event.target)) {
      setShowSortItem(true);
    }

    if (
      filterTagsRef.current &&
      !filterTagsRef.current.contains(event.target)
    ) {
      if (!isForcefullyPrevented) {
        setShowTags(true);
      }
    }
    if (
      filterOwnershipRef.current &&
      !filterOwnershipRef.current.contains(event.target)
    ) {
      setShowFilter(true);
    }
  };

  useEffect(() => {
    if (!showSortItem || !showTags || !showFilter) {
      document.addEventListener("click", HandleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", HandleOutsideClick);
    };
  }, [showSortItem, showTags, showFilter]);

  useEffect(() => {
    if (bookTagsStatus === ApiStatus.NotRun) {
      dispatch(fetchBookTags());
    }
  }, [dispatch, bookTags, bookTagsStatus]);

  const handleSearchChange = (event) => {
    const query = event?.target?.value?.toLowerCase() ?? "";
    setSearchQuery(query);
  };
  useEffect(() => {
    if (searchQuery !== null) {
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        search: searchQuery,
      }));
    }
  }, [searchQuery]);

  return (
    <div className="mb-5 pr-[19px]">
      <div className="mx-auto pb-[15px]">
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={"search"}
          handleSearchChange={handleSearchChange}
        />
      </div>
      <hr />
      <div ref={sortingRef}>
        <Sort
          SortIcon={SortOutlinedIcon}
          sortingItemList={sortingItemList}
          hideDropDown={hideSortDropDown}
          sethideDropDown={sethideSortDropDown}
          setShowItem={setShowSortItem}
          showItem={showSortItem}
          sortingRef={sortingRef}
          defaultItem={sortDefaultItem}
          setSortFilter={setDelaySortFilter}
        />
      </div>
      <hr />
      <div ref={filterTagsRef}>
        <Filter
          filterItemList={filterItemTags}
          filterLoadStatus={bookTagsStatus}
          FilterIcons={TagOutlinedIcon}
          title={"Filter:"}
          filterByTitle={"Tags"}
          enableSearch={true}
          enableSelectAll={true}
          showFilter={showTags}
          setShowFilter={setShowTags}
          handleOutsideClick={HandleOutsideClick}
          filterType={"tags"}
          filterChange={setDelayFilterTags}
        />
      </div>
      <div ref={filterOwnershipRef}>
        <Filter
          filterItemList={filterItemListOwnerShip}
          FilterIcons={SystemUpdateAltIcon}
          filterByTitle={"Ownership"}
          enableSearch={false}
          enableSelectAll={false}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          filterType={"ownership"}
          filterChange={setDelayFilterOwnership}
        />
      </div>
    </div>
  );
};

export default React.memo(LibraryViewMenu);
