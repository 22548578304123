export const subscriptionTypes = {
  Trial: "free trial",
  Free: "free",
  Monthly: "monthly",
  Yearly: "yearly",
  Life: "lifetime",
  OneTime: "oneTime",
  Deleted: "deleted",
};

export const paymentStatuses = {
  Free: "free",
  Trial: "free trial",
  Paid: "paid",
  Deleted: "deleted",
};

export const subscriptionLetters = {
  [subscriptionTypes.Monthly]: "M",
  [subscriptionTypes.Yearly]: "Y",
  [subscriptionTypes.Life]: "L",
};

export const paidSubscriptionTypes = [
  subscriptionTypes.Monthly,
  subscriptionTypes.Yearly,
  subscriptionTypes.Life,
];

const monthlyCost = 9;
const yearlyCost = 84;
const lifetime = 350;
const yearlyMonthlyCost = (yearlyCost / 12).toFixed(0);

export const subscriptionData = {
  [subscriptionTypes.Free]: {
    price: "$0",
    benefits: [
      {
        paid: true,
        text: "Unlimited book sync",
      },
      {
        paid: false,
        text: "No privacy setting for data",
      },
      {
        paid: false,
        text: "100 MB additional storage",
      },
      {
        paid: false,
        text: "No AI tokens (buy separately)",
      },
    ],
  },
  [subscriptionTypes.Monthly]: {
    price: `$${monthlyCost} monthly`,
    benefits: [
      {
        paid: true,
        text: "Unlimited book sync",
      },
      {
        paid: true,
        text: "Privacy setting for data",
      },
      {
        paid: true,
        text: "1 GB additional storage",
      },
      {
        paid: true,
        text: "AI tokens each month (soon)",
      },
      {
        paid: true,
        text: `Monthly cost: $${monthlyCost}, billed monthly`,
      },
    ],
  },
  [subscriptionTypes.Yearly]: {
    price: `$${yearlyCost} yearly`,
    benefits: [
      {
        paid: true,
        text: "Unlimited book sync",
      },
      {
        paid: true,
        text: "Privacy setting for data",
      },
      {
        paid: true,
        text: "1 GB additional storage",
      },
      {
        paid: true,
        text: "AI tokens each month (soon)",
      },
      {
        paid: true,
        text: `Monthly cost: $${yearlyMonthlyCost}, billed yearly`,
      },
    ],
  },
  [subscriptionTypes.Life]: {
    price: `$${lifetime}`,
    benefits: [
      {
        paid: true,
        text: "Unlimited book sync",
      },
      {
        paid: true,
        text: "Privacy setting for data",
      },
      {
        paid: true,
        text: "1 GB additional storage",
      },
      {
        paid: true,
        text: "AI tokens each month (soon)",
      },
      {
        paid: true,
        text: `One-time cost of $${lifetime}, billed once`,
      },
    ],
  },
};
