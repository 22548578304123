import React from "react";
import { DeepTabs } from "../Common/Tabs";
import Profile from "../Profile/Profile";
import Billing from "../Billing/Billing";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import "../Profile/profile.css";
import "./Profile-tabs.css";

const ProfileInfoTabs = [
  { label: "Profile" },
  { label: "Billing" },
  { label: "Delete Account" },
];

const ProfileTabs = () => {
  return (
    <div className="feedParentContainer">
      <div
        style={{ height: "auto" }}
        className={`feedBoxLayout w-full profile-main-wrapper mt-[10px]`}
      >
        <div
          className={`max-w-[1388px] w-full  border border-[#e5e4e4] rounded-[10px] relative pb-[30px]`}
        >
          <div className=" flex justify-between items-center w-full p-[5px] "></div>
          <DeepTabs tabsInfo={ProfileInfoTabs} largeFontSize={true}>
            <Profile />
            <Billing />
            <DeleteAccount />
          </DeepTabs>
        </div>
      </div>
    </div>
  );
};

export default ProfileTabs;
