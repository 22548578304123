import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TagIcon from "@mui/icons-material/Tag";
import IdentifyWhite from "../Assets/IdentifyWhite";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import TuneIcon from "@mui/icons-material/Tune";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const iconProvider = (iconName) => {
  switch (iconName) {
    case "DynamicFeedOutlined":
      return <DynamicFeedIcon />;
    case "LibraryBooksOutlined":
      return <LibraryBooksIcon />;
    case "TagOutlined":
      return <TagIcon />;
    case "LightbulbOutlined":
      return <LightbulbOutlinedIcon />;
    case "FormatListBulletedOutlined":
      return <FormatListBulletedIcon />;
    case "GridViewOutlined":
      return <GridViewIcon />;
    case "AccountTreeOutlined":
      return <AccountTreeOutlinedIcon />;
    case "HubOutlined":
      return <HubOutlinedIcon />;
    case "TipsAndUpdatesOutlined":
      return <TipsAndUpdatesOutlinedIcon />;
    case "MenuRounded":
      return <MenuOpenOutlinedIcon />;
    case "IdentifyWhite":
      // eslint-disable-next-line react/jsx-pascal-case
      return <IdentifyWhite />;

    case "Playlist_Add":
      return <PlaylistAddRoundedIcon />;

    case "TuneIcon":
      return <TuneIcon />;
    case "BookIcon":
      return <AutoStoriesIcon />;

    case "Notifications":
      return NotificationsIcon;
    case "DynamicFeed":
      return DynamicFeedIcon;

    //Keywords+ideacar+highlights
    case "highlights":
      return <StopRoundedIcon />;
    case "ideaCards":
      return <LightbulbOutlinedIcon />;
    case "KEYWORDS":
      return <VpnKeyRoundedIcon />;
    case "MAIN CLAIMS":
      return <KeyboardDoubleArrowRightRoundedIcon />;
    case "QUOTES":
      return <KeyboardDoubleArrowRightRoundedIcon />;
    case "EXAMPLES":
      return <ForumRoundedIcon />;
    case "ACTIONS ITEMS":
      return <CampaignRoundedIcon />;
    case "CUSTOM":
      return <SettingsRoundedIcon />;
    default:
      return null; // Return null or a default icon component if the icon name is not recognized
  }
};
